<template>
    <div>
        <b-row class="content-header">
            <!-- Content Left -->
            <b-col class="content-header-left mb-2" cols="12" md="9">
                <b-row class="breadcrumbs-top">
                    <b-col cols="12">
                        <h2 class="float-left mb-0">History</h2>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-card no-body>
            <b-card-header>
                <b-card-title>Downloaded</b-card-title>
                <b-card-text
                    >Download link will expire after 6 hours</b-card-text
                >
            </b-card-header>
            <b-overlay :show="show" rounded="sm" variant="transparent">
                <b-table :fields="fields" :items="items" responsive>
                    <template #cell(host)="data">
                        <a :href="data.item.original_link" target="_blank"
                            ><img :src="iconHost(data.value)" />{{
                                data.value
                            }}</a
                        >
                    </template>
                    <template #cell(name)="data">
                        <a :href="data.item.download_link">{{ data.value }}</a>
                    </template>
                    <template #cell(size)="data">
                        {{ formatBytes(data.value) }}
                    </template>
                    <template #cell(status)="data">
                        <span :class="statusVariant(data.value)">
                            {{
                                data.value.replace(/^./, (str) =>
                                    str.toUpperCase()
                                )
                            }}
                        </span>
                    </template>
                </b-table>

                <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    first-number
                    last-number
                    align="center"
                    class="mt-1"
                    @input="handlePageClick"
                    ><template #prev-text>
                        <feather-icon size="18" icon="ChevronLeftIcon" />
                    </template>
                    <template #next-text>
                        <feather-icon size="18" icon="ChevronRightIcon" />
                    </template>
                </b-pagination>
            </b-overlay>
        </b-card>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BTable,
    BCardHeader,
    BCardTitle,
    BOverlay,
    BPagination,
    BCardText,
} from "bootstrap-vue";
import formatBytes from "../../mixins/format-bytes";
export default {
    components: {
        BCard,
        BRow,
        BCol,
        BTable,
        BCardHeader,
        BCardTitle,
        BOverlay,
        BPagination,
        BCardText,
    },
    mixins: [formatBytes],
    created() {
        this.fetchData();
    },
    computed: {
        iconHost() {
            return (data) => "images/hoster/" + data + ".png";
        },
        statusVariant() {
            const statusColor = {
                working: "text-success",
                expired: "text-danger",
            };

            return (status) => statusColor[status];
        },
    },
    data() {
        return {
            show: false,
            currentPage: 1,
            perPage: 0,
            rows: 0,
            fields: ["host", "name", "size", "ip_address", "status", "date"],
            items: [],
        };
    },
    methods: {
        fetchData() {
            this.show = true;
            this.$store
                .dispatch("historyDownload", this.currentPage)
                .then((res) => {
                    this.items = res.data.data;
                    this.perPage = res.data.meta.per_page;
                    this.rows = res.data.meta.total;
                    this.currentPage = res.data.meta.current_page;
                    this.items = res.data.data;
                    this.show = false;
                })
                .catch((err) => console.log(err));
        },
        handlePageClick(pageNumber) {
            this.currentPage = pageNumber;
            this.fetchData();
        },
    },
};
</script>

<style></style>
